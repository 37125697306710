<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row>
        <el-col class="padding-right-xs">
          <el-card class="margin-bottom-xs">
            <div slot="header" class="clearfix">
              <p>基本信息</p>
            </div>
            <el-row class="twice">
              <el-form-item label="建议标题" prop="proposalTitle">
                <el-input v-model="dataInfo.proposalTitle" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="建议状态" prop="proposalStatus">
                <p class="padding-left text-bold">
                  <span v-if="dataInfo.proposalStatus == 0" class="text-grey">未提交</span>
                  <span v-else-if="dataInfo.proposalStatus == 1" class="text-blue">已提交</span>
                  <span v-else-if="dataInfo.proposalStatus == 2" class="text-mauve">已入围</span>
                  <span v-else-if="dataInfo.proposalStatus == 3" class="text-green">已采纳</span>
                  <span v-else-if="dataInfo.proposalStatus == -1" class="text-red">无效建议</span>
                  <span v-else-if="dataInfo.proposalStatus == -2" class="text-red">无法实施</span>
                </p>
              </el-form-item>
              <el-form-item label="建议类型" prop="proposalType">
                <el-input v-model="dataInfo.proposalType" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="建议分类" prop="proposalCategory">
                <el-input v-model="dataInfo.proposalCategory" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="建议人" prop="createUserName">
                <el-input v-model="dataInfo.createUserName" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="所属部门" prop="createDepartName">
                <el-input v-model="dataInfo.createDepartName" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="提出时间" prop="proposalTime">
                <l-datepicker v-model="dataInfo.proposalTime" :disabled="true"></l-datepicker>
              </el-form-item>
              <el-form-item label="要求时间" prop="requestTime">
                <l-datepicker v-model="dataInfo.requestTime" :disabled="true"></l-datepicker>
              </el-form-item>
              <el-form-item label="建议内容" prop="content">
                <el-input type="textarea" :rows="3" v-model="dataInfo.content" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="改进方法" prop="proposalMethod">
                <el-input type="textarea" :rows="3" v-model="dataInfo.proposalMethod" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="改进结果" prop="proposalResult">
                <el-input type="textarea" :rows="3" v-model="dataInfo.proposalResult" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="padding-right-xs" v-if="editType === 3">
          <el-card class="margin-bottom-xs">
            <el-row>
              <el-form-item label="入围说明" prop="approveInfo">
                <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save">入围</el-button>
                <el-button @click="_cancle">取消</el-button>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
        <el-col class="padding-right-xs" v-if="editType === 5">
          <el-card class="margin-bottom-xs">
            <el-row>
              <el-form-item label="无效说明" prop="approveInfo">
                <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="danger" @click="save">建议无效</el-button>
                <el-button @click="_cancle">取消</el-button>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
        <el-col class="padding-right-xs" v-if="editType === 4">
          <el-card class="margin-bottom-xs">
            <el-row>
              <el-form-item label="入围说明" prop="approveInfo">
                <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="部门采纳说明" prop="departmentSuggest">
                <el-input type="textarea" :rows="3" v-model="dataInfo.departmentSuggest" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save">采纳</el-button>
                <el-button @click="_cancle">取消</el-button>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
        <el-col class="padding-right-xs" v-if="editType === 6">
          <el-card class="margin-bottom-xs">
            <el-row>
              <el-form-item label="入围说明" prop="approveInfo">
                <el-input type="textarea" :rows="3" v-model="dataInfo.approveInfo" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="无法实施说明" prop="departmentSuggest">
                <el-input type="textarea" :rows="3" v-model="dataInfo.departmentSuggest" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="danger" @click="save">无法实施</el-button>
                <el-button @click="_cancle">取消</el-button>
              </el-form-item>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      editType: '',
      activeName: 'base',
      dataRules: {}
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    save() {
      if (!this.editType) return
      this.dataInfo.editType = this.editType
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/proposal/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
